<template>
  <div id="permissions">
    <div class="container-fluid">
      <!-- Add Edit Section -->
      <!-- @submit="onSubmit" @reset="onReset" -->
      <div class="add-section" v-if="canAccess(['store-permission','update-permission'])">
        <b-modal @hidden="closeModal" id="permissions-modal" :cancel-title="$t('close')" hide-header-close :ok-title="$t('save')"
                 :title="$t('sidebar.permissions')" @ok="submit">
          <b-form class="d-grid grid-cols-1 gap-5">
            <div class="feild">
              <label for="">{{ $t('name') }}</label>
              <b-form-input
                type="text"
                v-model="payload.name"
                required
              ></b-form-input>
            </div>
          </b-form>
        </b-modal>
      </div>
      <!-- Filters Section -->
      <div class="filters-section">
        <iq-card class="w-100">
          <template v-slot:body>
            <div class="filter-container" @change="filtering">
              <b-form-input
                type="text"
                :placeholder="$t('filter-name')"
                v-model="filterData.name"
                required
              ></b-form-input>
            </div>
          </template>
        </iq-card>
      </div>
      <!-- Tabel Section -->
      <div class="table-sections">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('permissions-list') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button v-if="canAccess(['store-permission'])" variant="primary" @click="addInit" size="lg">{{
                $t('add')
              }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-table responsive v-if="canAccess(['get-permission','update-permission','delete-permission'])"
                     :items="permissions.data" :fields="fields" class="mb-0 table-borderless">
              <template v-slot:cell(actions)="data">
                <div class="actions-list d-flex align-items-center">
                  <b-button v-if="canAccess(['update-permission'])" v-b-tooltip.top="$t('edit')"
                            variant=" iq-bg-success" @click="update(data.item)" size="md"><i
                    class="ri-ball-pen-fill p-0"></i></b-button>
                  <!-- <b-button v-if="canAccess(['delete-permission'])" v-b-tooltip.top="$t('remove')"
                            variant=" iq-bg-danger" size="md" @click="remove(data.item.id)"><i
                    class="ri-delete-bin-line p-0"></i></b-button> -->
                </div>
              </template>
            </b-table>
          </template>
        </iq-card>
      </div>
      <!-- Pagination Section -->
      <div class="pagination">
        <iq-card class="w-100 d-flex justify-content-center">
          <template v-slot:body>
            <b-pagination
              v-model="filterData.page"
              :total-rows="permissions.total"
              :per-page="permissions.per_page"
            ></b-pagination>
          </template>
        </iq-card>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      filterData: {
        page: 1,
        name: '',
        take: 10
      },
      payload: {
        name: ''
      }
    }
  },
  computed: {
    fields () {
      return [
        {
          label: this.$t('name'),
          key: 'name'
        },
        {
          label: this.$t('actions'),
          key: 'actions'
        }
      ]
    }
  },
  methods: {
    filtering () {
      this.filterData.page = 1
      this.getPermissions(this.filterData)
    },
    addInit () {
      this.$bvModal.show('permissions-modal')
    },
    update (data) {
      Object.assign(this.payload, data)
      this.$bvModal.show('permissions-modal')
    },
    remove (id) {
      this.confermMsg(() => {
        this.removePermissions(id)
      })
    },
    submit (bvModalEvent) {
      bvModalEvent.preventDefault()
      if (!this.payload.id) {
        this.addPermissions(this.payload).then(() => {
          this.$bvModal.hide('permissions-modal')
          this.payload = this.resetObject(this.payload)
          this.getPermissions(this.filterData)
        })
      } else {
        this.updatePermissions(this.payload).then(() => {
          this.$bvModal.hide('permissions-modal')
          this.payload = this.resetObject(this.payload)
          this.getPermissions(this.filterData)
        })
      }
    },
    changeStatus () {
    },
    closeModal (bvModalEvent) {
      bvModalEvent.preventDefault()
      this.payload = this.resetObject(this.payload)
    }
  },
  watch: {
    'filterData.page': {
      handler: function (value) {
        this.getPermissions(this.filterData)
      },
      deep: true
    }
  },
  mounted () {
      this.authUserPermissions();
      this.getPermissions()
  }
}
</script>
